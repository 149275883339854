import axios from '@axios'
import qs from 'qs'

const prefixPath = '/api/system/menu'
export default {

  get(id) {
    return axios.get(`${prefixPath}/get?id=${id}`)
  },

  save(data) {
    return axios.post(`${prefixPath}/saveOrUpdate`, data)
  },

  list() {
    return axios.get(`${prefixPath}/getAll`)
  },

  getMenuTree() {
    return axios.get(`${prefixPath}/getMenuNodeList`)
  },

  delete(id) {
    return axios.post(`${prefixPath}/delete`, qs.stringify({
      id,
    }))
  },

  allocateRoleMenus(data) {
    return axios.post(`${prefixPath}/allocateRoleMenus`, data)
  },

  getRoleMenus(roleId) {
    return axios.get(`${prefixPath}/getRoleMenus`, {
      params: {
        roleId,
      },
    })
  },

}

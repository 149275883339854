<template>
  <v-card v-if="$can('menu_list', 'menu')">
    <v-card-title
      v-if="$can('menu_save', 'menu')"
      class="d-flex align-center flex-wrap pb-0"
    >
      <div class="d-flex align-center pb-5">
        <!-- create invoice -->
        <v-btn
          color="primary"
          class="me-3"
          @click="editItem()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>新增</span>
        </v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :hide-default-footer="true"
        :items-per-page="pages.itemsPerPage"
        :server-items-length="pages.serverItemsLength"
        :headers="getHeader()"
        no-data-text="无数据"
        :items="menus"
      >
        <template #[`item.name`]="{ item }">
          <span v-if="item.parentId != -1">&nbsp;&nbsp;&nbsp;&nbsp;</span>
          {{ `${item.name}` }}
        </template>
        <template #[`item.level`]="{ item }">
          {{ `${getCharNum(item.level)}级标题` }}
        </template>

        <template #[`item.actions`]="{ item }">
          <v-tooltip
            v-if="$can('menu_edit', 'menu')"
            top
          >
            <template #activator="{ on, attrs }">
              <v-icon
                small
                class="me-2"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <span>编辑</span>
          </v-tooltip>
          <v-tooltip
            v-if="$can('menu_delete', 'menu')"
            top
          >
            <template #activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                @click="openDeleteDialog(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <span>删除</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>

    <v-navigation-drawer
      v-model="editDialog"
      temporary
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 750 : '100%'"
      app
    >
      <v-card height="100%">
        <div class="drawer-header d-flex align-center">
          <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}菜单</span>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-5"
            color="primary"
            :disabled="commitFlag"
            :loading="commitFlag"
            @click="handleFormSubmit()"
          >
            保存
          </v-btn>
          <v-btn
            icon
            small
            @click="editDialog = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </div>

        <v-card-text
          style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
          class="overflow-auto"
        >
          <v-card>
            <v-form
              ref="editForm"
              lazy-validation
              @submit.prevent="handleFormSubmit"
            >
              <v-card-text class="pa-3">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="name"><span style="color: red">*</span>菜单名称：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        id="name"
                        v-model="editedItem.name"
                        :rules="[rules.required]"
                        required
                        counter="25"
                        label="菜单名称"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="code">菜单代码：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        id="code"
                        v-model="editedItem.code"
                        :rules="[]"
                        counter="25"
                        label="菜单代码"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="parentName">上级菜单：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        v-show="false"
                        id="parentId"
                        v-model="editedItem.parentId"
                      ></v-text-field>
                      <v-text-field
                        id="href"
                        v-model="parentName"
                        :rules="[]"
                        counter="25"
                        label="上级菜单"
                        readonly
                        outlined
                        clearable
                        dense
                        @click="treeDialog = true"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="description"><span style="color: red">*</span>描述：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        v-model="editedItem.description"
                        :rules="[rules.required]"
                        counter="25"
                        label="描述"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="shown">是否显示：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-radio-group
                        id="shown"
                        v-model="editedItem.shown"
                        style="margin-top: -5px"
                        row
                      >
                        <v-radio
                          label="是"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          label="否"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="orderNum">排序：</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        id="orderNum"
                        v-model="editedItem.orderNum"
                        label="排序"
                        type="Number"
                        outlined
                        clearable
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
          </v-card>
        </v-card-text>
      </v-card>

      <TreeSelect
        v-model="treeDialog"
        :active="active"
        :selected-tree-data="selectedTreeData"
        title="选择上级菜单"
        @getActive="getActive"
      ></TreeSelect>
    </v-navigation-drawer>

    <v-dialog
      v-model="deleteDialog"
      width="300"
    >
      <v-card>
        <v-card-text> 是否删除 {{ this.deleteData.name }} ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteItem(id)"
          >
            确认删除
          </v-btn>
          <v-btn
            color="normal"
            text
            @click="deleteDialog = false"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiClose,
} from '@mdi/js'
import { required } from '@core/utils/validation'
import TreeSelect from '@/components/select/TreeSelect'
import MenuApi from '@/api/system/menu.js'

export default {
  name: 'Menu',
  components: {
    TreeSelect,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
      },
      pages: {
        itemsPerPage: -1,
        serverItemsLength: 0,
      },
      charNum: ['一', '二', '三', '四', '五', '六'],
      headers: [
        { text: '编号', sortable: false, value: 'id' },
        { text: '菜单名称', value: 'name' },
        { text: '菜单代码', value: 'code' },
        { text: '菜单级数', value: 'level' },

        // { text: '前端图标', value: 'icon' },
        { text: '排序', value: 'orderNum' },
        { text: '操作', value: 'actions' },
      ],
      rules: {
        required,
      },
      menus: [],
      editDialog: false,
      treeDialog: false,
      iconDialog: false,
      deleteDialog: false,
      editedItem: {},
      defaultItem: {
        parentId: -1,
        orderNum: 1,
        shown: true,
        code: '',
        name: '',
        description: '',
      },
      loadings: {
        submitLoading: false,
      },
      selectedTreeData: [],
      parentName: '',
      selectNode: [],
      active: [],
      deleteData: {},
      commitFlag: false,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '编号', sortable: false, value: 'id' },
        { text: '菜单名称', value: 'name' },
        { text: '菜单代码', value: 'code' },
        { text: '菜单级数', value: 'level' },

        // { text: '前端图标', value: 'icon' },
        { text: '排序', value: 'orderNum' },
      ]
      if (this.$can('menu_edit', 'menu')
          || this.$can('menu_delete', 'menu')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    async editItem(item) {
      this.active = []
      if (item) {
        this.editedItem = {}
        await MenuApi.get(item.id).then(res => {
          const { data } = res.data
          this.editedItem = data
        })

        // 通过遍历找到items下中被嵌套的数据
        if (item.parentId !== -1) {
          const node = this.findMenuNode(this.editedItem.parentId, this.selectedTreeData)
          this.parentName = node.name
          const obj = {
            id: item.parentId,
          }
          this.active.push(obj)
        }
      } else {
        this.initEditedItem()
        this.$nextTick(() => {
          this.$refs.editForm.resetValidation()
        })
      }
      this.commitFlag = false
      this.editDialog = true
    },
    deleteItem() {
      MenuApi.delete(this.deleteData.id).then(res => {
        this.$toast.success('删除成功！')
        this.deleteDialog = false
        this.loadData()
      })
    },
    getCharNum(num) {
      return this.charNum[num]
    },
    getActive(val) {
      this.selectNode = val
      if (val.length > 0) {
        this.parentName = val[0].name
        this.editedItem.parentId = val[0].id
      } else {
        this.parentName = ''
        this.editedItem.parentId = -1
      }
    },
    getIconName(name) {
      this.editedItem.icon = name
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return
      if (this.commitFlag) return
      this.commitFlag = true
      MenuApi.save(this.editedItem).then(res => {
        this.$toast.success('保存成功')
        this.commitFlag = false
        this.closeDialog()
        this.loadData()
      })
    },
    loadData() {
      if (this.$can('menu_list', 'menu')) {
        MenuApi.list().then(res => {
          this.menus = res.data.data
          this.pages.serverItemsLength = this.menus.length
        })
      }
      if (this.$can('menu_tree', 'menu')) {
        MenuApi.getMenuTree().then(res => {
          const { data } = res.data
          this.selectedTreeData = data
        })
      }
    },
    closeDialog() {
      this.$refs.editForm.resetValidation()
      this.editedItem = this.defaultItem
      this.parentName = ''
      this.editDialog = false
    },
    findMenuNode(id, menu) {
      if (menu.length === 0) {
        return
      }
      let node
      for (let i = 0; i < menu.length; i++) {
        const item = menu[i]
        if (item.id === id) {
          node = item
          break
        }
        if (item.children && item.children.length > 0) {
          node = this.findMenuNode(id, item.children)
        }
      }

      return node
    },
    initEditedItem() {
      this.editedItem.id = ''
      this.editedItem.name = ''
      this.editedItem.description = ''
      this.editedItem.shown = true
      this.editedItem.parentId = -1
      this.editedItem.orderNum = 1
      this.editedItem.code = ''
      this.parentName = ''
    },
    openDeleteDialog(data) {
      this.deleteData = data
      this.deleteDialog = true
    },
  },
}
</script>
